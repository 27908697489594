<template>
  <div class="home-advantages">
    <div class="container">
      <h2 class="title">{{ $t("advantagesTitle") }}</h2>
      <!-- Content -->
      <div class="home-advantages__content">
        <div class="home-advantages__content-descr">
          <ul class="home-advantages__content-list">
            <li
              class="home-advantages__item"
              v-for="(text, index) in $t('advantagesContent')"
              :key="index"
            >
              {{ text.text }}
            </li>
          </ul>
        </div>

        <div class="home-advantages__content-quote home-quote">
          <p class="home-quote__text">{{ $t("advantagesQuote") }}</p>
          <div class="home-quote__author">{{ $t("advantagesAuthor") }}</div>
        </div>
      </div>
      <!-- Numbers -->
      <div class="home-advantages__numbers home-number">
        <div
          class="home-number__item"
          v-for="(item, index) in $t('statistics')"
          :key="index"
        >
          <div class="home-number__title">
            <strong>{{ item.number }}</strong
            >{{ item.title }}
          </div>
          <div class="home-number__descr">{{ item.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeAdvantages",
};
</script>
